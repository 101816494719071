/* for items reveal */
/* to top button */
body {
	margin: 0;
	padding: 0;
}


html {
	scroll-behavior: smooth;
}

a {
	text-decoration: none;
}

h1 {
	margin: 0;
	padding: 0;
	font-family: $font-family_1;
}

h2 {
	margin: 0;
	padding: 0;
	font-family: $font-family_1;
}

h3 {
	margin: 0;
	padding: 0;
	font-family: $font-family_1;
}

h4 {
	margin: 0;
	padding: 0;
	font-family: $font-family_1;
}

p {
	font-family: $font-family_2;
}

header {
	.content {
		display: flex;
		flex-direction: column;

		.primary-menu {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 15px 120px;
			background: #ddd;

			.col-left {
				display: block;
				font-size: .9em;
				font-family: $font-family_3;

				span {
					&:nth-child(1) {
						margin-right: 35px;
					}

					border-left: 1px solid #b9b9b980;
				}

				i {
					color: $color_1;
					margin-left: 10px;
					margin-right: 10px;
					font-size: 1em;
				}
			}

			.col-right {
				a {
					color: $color_2;
					padding: 16px;
					border-left: 1px solid #b9b9b980;
					transition: .5s ease;

					&:hover {
						color: $color_1;
					}

					&:nth-child(3) {
						border-right: 1px solid #b9b9b980;
					}
				}
			}
		}
	}

	.main-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 0;
		transition: background .5s ease;
		padding: 0 120px;
		background-color: #075b72;

		.col-right {
			padding: 20px 15px;

			a {
				color: $color_3;
				margin-left: 15px;
				font-family: $font-family_1;
				font-size: 18px;
				letter-spacing: 1px;
				font-weight: 400;
				position: relative;
				padding: 10px 0;

				&:hover {
					color: $color_10;
				}
			}

			a.active {
				color: $color_10;
			}

			i {
				font-size: 12px;
				padding-left: 6px;
			}

			.menu1 {
				float: left;

				.submenu {
					position: absolute;
					display: flex;
					flex-direction: column;
					background: #ffff;
					width: 200px;
					border-radius: 5px;
					top: 109px;
					visibility: hidden;
					transform: scaleY(0);
					transform-origin: top;
					transition: .8s ease;
					padding: 10px 0;
					box-shadow: 0px 0px 36px -6px rgb(1 1 1 / 18%);

					a {
						&::before {
							content: '';
							background: skyblue;
							width: 0px;
							height: 3px;
							display: inline-block;
							vertical-align: middle;
							margin-right: 5px;
							transition: .5s;
						}

						&:hover {
							&::before {
								width: 15px;
							}
						}
					}
				}

				&:hover {
					.submenu {
						visibility: visible;
						transform: scaleY(1);
					}
				}
			}

			.submenu {
				a {
					padding: 8px 5px;
					font-size: 18px;
				}
			}

			.search-box {
				font-family: $font-family_4;
				position: relative;
				background: #ffffff;
				height: 35px;
				padding: 6px;
				border-radius: 40px;
				box-shadow: 0 0 25px 0 rgb(51 58 69 / 10%);
				float: right;
				margin-left: 21px;
				margin-top: -4px;

				input {
					border: none;
					background: none;
					outline: none;
					float: left;
					padding: 0;
					font-size: 20px;
					line-height: 21px;
					width: 0px;
					transition-timing-function: ease;
					transition: all .5s;
					color: black;

					&:focus {
						width: 170px;
						padding: 0 6px;
					}
				}

				&:hover {
					input {
						width: 150px;
						padding: 0 6px;
					}
				}
			}

			.search-btn {
				border: none;
				background: none;
				outline: none;
				width: 30px;
				margin-top: -13px;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;

				i {
					z-index: 1;
					background: skyblue;
					font-size: 11px;
					color: $color_5;
					padding: 17px;
					border-radius: 50px;
				}
			}
		}

		.col-left {
			display: flex;
			align-items: center;
		}
	}

	.main-header.sticky {
		background: #ffffff;
		position: fixed;
		z-index: 999;
		width: 100%;
		padding: 0;
		backdrop-filter: blur(10px);

		.col-right {
			margin-right: 100px;

			.menu1 {
				.submenu {
					top: 61px;
				}
			}
		}

		.col-left {
			margin-left: 100px;
		}
	}
}

section {
	padding: 30px 20px;
}

.image-slider {
	padding: 0;

	.content {
		.slider {
			width: 100%;
			height: 500px;
			overflow: hidden;
		}

		.slides {
			width: 500%;
			height: 500%;
			display: flex;

			input {
				display: none;
			}
		}

		.slide {
			width: 20%;
			transition: 2s;

			img {
				width: 100%;
				height: 500px;
				object-fit: cover;
			}
		}

		.navigation-manual {
			position: absolute;
			width: 100%;
			display: flex;
			justify-content: center;
			margin-top: 460px;
		}

		.manual-btn {
			border: 2px solid skyblue;
			padding: 5px;
			border-radius: 10px;
			cursor: pointer;
			transition: 1s;

			&:not(:last-child) {
				margin-right: 40px;
			}

			&:hover {
				background: skyblue;
			}
		}

		#radio1 {
			&:checked {
				~ {
					.first {
						margin-left: 0;
					}

					.navigation-auto {
						.auto-btn1 {
							background: skyblue;
						}
					}
				}
			}
		}

		#radio2 {
			&:checked {
				~ {
					.first {
						margin-left: -20%;
					}

					.navigation-auto {
						.auto-btn2 {
							background: skyblue;
						}
					}
				}
			}
		}

		#radio3 {
			&:checked {
				~ {
					.first {
						margin-left: -40%;
					}

					.navigation-auto {
						.auto-btn3 {
							background: skyblue;
						}
					}
				}
			}
		}

		#radio4 {
			&:checked {
				~ {
					.first {
						margin-left: -60%;
					}

					.navigation-auto {
						.auto-btn4 {
							background: skyblue;
						}
					}
				}
			}
		}

		.navigation-auto {
			position: absolute;
			display: flex;
			width: 100%;
			justify-content: center;
			margin-top: 460px;

			div {
				border: 2px solid skyblue;
				padding: 5px;
				border-radius: 10px;
				transition: 1s;

				&:not(:last-child) {
					margin-right: 40px;
				}
			}
		}

		.text-box {
			max-width: 400px;
			position: absolute;
			margin: 0 200px;
			top: 205px;
			padding: 51px 50px;
			border-radius: 10px;
			transition: .2s ease;
			background: #00000014;
			backdrop-filter: blur(6px);

			&:hover {
				backdrop-filter: blur(15px);
			}

			h1 {
				font-size: 1.7em;
				font-family: $font-family_1;
				color: $color_6;
				width: 300px;
			}

			p {
				font-size: 1em;
				width: 310px;
				line-height: 25px;
				color: $color_4;
				font-family: $font-family_2;
				margin-top: 8px;
				margin-bottom: 26px;
			}
		}
	}
}

.button {
	font-size: 17px;
	font-family: $font-family_1;
	padding: 10px 20px;
	background: skyblue;
	color: $color_7;
	border-radius: 2px;
	transition: .5s ease;
	float: unset;

	&:hover {
		background: #fff;
		color: $color_1;
	}
}

.services {
	padding: 100PX 10px;
	background-color: #f7f7f7;

	.content {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		.card {
			width: 220px;
			padding: 15px 20px;
			background: #fff;
			border-radius: 3px;
			box-shadow: 0px 0px 70px -20px rgba(4, 107, 175, 0.6);
			margin: 10px;
			transition: .4s ease;
			cursor: pointer;
			text-align: center;
			font-family: popin;

			&:hover {
				background: linear-gradient(skyblue, #0878ac);

				.service-icon {
					color: $color_7;
				}

				.service-info {
					p {
						color: $color_4;
					}

					h5:hover {
						color: white !important;
					}

					a {
						color: $color_10;
					}
				}
			}

			h5 {
				height: 70px;
			}

			.service-icon {
				font-size: 50px;
				color: $color_1;
				text-align: center;
				transition: .4s ease;
			}

			.service-info {
				h3 {
					font-size: 23px;
					font-family: $font-family_1;
					color: $color_8;
					text-align: center;
					margin-top: 8px;
					transition: .5s ease;
				}

				p {
					font-size: 14px;
					font-family: $font-family_2;
					color: $color_9;
					text-align: center;
					margin-bottom: 20px;
					margin-top: 20px;
					line-height: 19px;
					transition: .5s ease;
				}

				a {
					font-size: 13px;
					font-family: $font-family_2;
					color: $color_9;
					text-align: center;
					display: block;
					margin-bottom: 17px;
					transition: .5s ease;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		.card-second {
			background: linear-gradient(skyblue, #0878ac);
			border-radius: 3px;

			.service-icon {
				font-size: 50px;
				color: $color_10;
				text-align: center;
			}

			.service-info {
				h3 {
					font-size: 24px;
					font-family: $font-family_1;
					color: $color_4;
					text-align: center;
					margin-top: 8px;
				}

				p {
					font-size: 14px;
					font-family: $font-family_2;
					color: $color_4;
					text-align: center;
					margin-bottom: 20px;
					margin-top: 20px;
					line-height: 19px;
				}

				a {
					font-size: 13px;
					font-family: $font-family_2;
					color: $color_7;
					text-align: center;
					display: block;
					margin-bottom: 17px;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

.work {
	padding: 50px 250px;
	display: flex;
	justify-content: center;

	.content {
		display: flex;
		flex-direction: row;
		justify-content: center;
		max-width: 800px;

		.col-left {
			width: 400px;
			height: 100%;
			// overflow: hidden;
			margin-right: 11px;

			img {
				width: 400px;
				height: 310px;
			}
		}

		.col-right {
			max-width: 390px;

			h3 {
				font-size: 2em;
				font-family: $font-family_1;
				color: $color_9;
			}

			p {
				font-size: .9em;
				font-family: $font-family_2;
				width: 437px;
				display: block;
				line-height: 21px;
			}

			ul {
				list-style: none;
				display: block;
				width: 396px;
				height: 91px;
				padding: 0;
			}

			li {
				float: left;
				margin: 5px 0;
				width: 180px;
				font-size: 16px;
				font-family: $font-family_2;
				cursor: pointer;

				&:hover {
					color: $color_1;
				}

				i {
					margin-right: 5px;
				}
			}

			.button {
				&:hover {
					background: #333;
					color: $color_4;
				}
			}
		}
	}
}

.projects {
	background: linear-gradient(#00000096, #000000b0),
		url(./../images/house.jpg);
	padding: 50px 50px;
	background-repeat: no-repeat;
	background-size: cover;
	object-fit: cover;

	.content {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		.card {
			width: 250px;
			margin: 20px;

			.icon {
				font-size: 40px;
				color: $color_4;
				text-align: center;
			}

			.info {
				h3 {
					color: $color_7;
					font-size: 55px;
					text-align: center;
					font-family: $font-family_1;
					margin: 7px 0;
					margin-top: -2px;
				}

				span {
					color: $color_4;
					font-size: 16px;
					font-family: $font-family_2;
					font-weight: bold;
					text-align: center;
					display: block;
				}
			}
		}
	}
}

.section-title {
	display: flex;
	justify-content: center;
	flex-direction: column;

	h3 {
		font-size: 2.2em;
		font-family: $font-family_1;
		display: block;
		text-align: center;
		position: relative;
		margin-bottom: 22px;

		&::before {
			content: "";
			position: absolute;
			width: 60px;
			height: 4px;
			background: skyblue;
			top: 52px;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	p {
		font-size: 1.1em;
		font-family: $font-family_2;
		display: block;
		text-align: center;
		width: 620px;
		margin: auto;
		line-height: 25px;
		margin-bottom: 45px;
	}
}

.blog {
	background: #ddd;

	.content {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		.card {
			width: 250px;
			margin: 10px;

			.card-img {
				width: 100%;
				height: 125px;

				img {
					position: relative;
					width: 100%;
					object-fit: cover;
					height: 100%;
				}
			}

			.card-info {
				h3 {
					font-size: 22px;
					font-family: $font-family_1;
					color: $color_9;
					margin-top: 10px;
				}

				p {
					font-size: 13px;
					font-family: $font-family_2;
					margin: 0;
					margin-top: 4px;
					line-height: 16px;
				}
			}
		}
	}
}

section.price-plan {
	padding: 50px 105px;
}

.price-plan {
	.content {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		.card {
			width: 250px;
			padding: 30px;
			background: #fff;
			padding-bottom: 40px;
			border-radius: 5px;
			margin: 15px;
			box-shadow: 0px 0px 36px -6px rgb(1 1 1 / 25%);

			h3 {
				font-size: 23px;
				font-family: $font-family_1;
				margin-bottom: 4px;
			}

			h2 {
				font-size: 40px;
				font-family: $font-family_1;
				float: left;
			}

			span {
				font-size: 18px;
				display: block;
				font-family: $font-family_2;
				font-weight: bold;
				margin-left: 61px;
				margin-top: 24px;
				letter-spacing: 1px;
			}

			ul {
				font-size: 5px;
				list-style: none;
				padding: 0;
				padding: 20px 0;
				margin-bottom: 15px;
			}

			li {
				font-size: 14px;
				font-family: $font-family_2;
				line-height: 40px;
				font-weight: 400;
			}

			i {
				color: $color_1;
				margin-right: 10px;
				font-size: 15px;
			}
		}
	}
}

.latest-idea {
	background: #333;
	padding: 0;

	.content {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		.col-left {
			width: 50%;

			img {
				width: 100%;
				object-fit: cover;
			}
		}

		.col-right {
			width: 47%;
			padding: 20px;

			h2 {
				font-size: 2em;
				color: $color_4;
				margin-top: 10px;
			}

			p {
				font-size: .9em;
				color: $color_4;
				margin-top: 5px;
			}

			ul {
				list-style: none;
				padding: 0;
				margin-top: 33px;
			}

			li {
				width: 252px;
				float: left;
				margin: 27px 0;
				margin-right: 47px;
			}

			i {
				color: $color_1;
				float: left;
				font-size: 45px;
				margin-right: 10px;
			}

			h3 {
				color: $color_4;
				font-size: 22px;
			}

			span {
				font-family: $font-family_2;
				font-size: 14px;
				color: $color_4;
			}
		}
	}
}

section.team-intro {
	padding: 50px 130px;
}

.team-intro {
	.content {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		.card {
			width: 250px;
			position: relative;
			box-shadow: 0px 0px 36px -6px rgb(1 1 1 / 18%);
			margin: 10px;

			.image {
				img {
					width: 100%;
					object-fit: cover;
				}

				.icon {
					width: 132px;
					position: absolute;
					bottom: 155px;
					left: 50%;
					transform: translateX(-50%);
					opacity: 0;
					transition: .4s ease;

					a {
						font-size: 16px;
						display: flex;
						float: left;
						width: 20px;
						height: 20px;
						background: skyblue;
						color: $color_4;
						justify-content: center;
						align-items: center;
						border-radius: 100%;
						padding: 3px;
						margin: 2px;

						&:nth-child(1) {
							background: #3b5998;
						}

						&:nth-child(2) {
							background: #00acee;
						}

						&:nth-child(3) {
							background: #0072b1;
						}

						&:nth-child(4) {
							background: #3ab4ec;
						}

						&:hover {
							background: #fff;
							color: $color_2;
						}
					}
				}
			}

			&:hover {
				.image {
					.icon {
						opacity: 1;
						bottom: 110px;
					}
				}
			}

			.info {
				h2 {
					font-size: 27px;
					text-align: center;
					margin-top: 5px;
				}

				span {
					font-size: 16px;
					font-family: $font-family_2;
					font-weight: 500;
					text-align: center;
					display: block;
					margin-top: 4px;
					margin-bottom: 20px;
				}
			}
		}
	}
}

.project {
	padding: 50px 180px;
	background: #ffffff;

	.content {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		.card {
			width: 300px;
			height: 298px;
			overflow: hidden;
			position: relative;
			margin: 10px;
			box-shadow: 0px 0px 36px -6px rgb(1 1 1 / 30%);

			.image {
				img {
					width: 100%;
					object-fit: cover;
				}
			}

			.info {
				position: absolute;
				top: 190px;
				color: $color_4;
				height: 115px;
				padding: 15px;
				background: linear-gradient(#00000005, #000000);
				transition: .4s ease;

				h3 {
					font-size: 24px;
					line-height: 23px;
					margin: 4px 0;
				}

				span {
					font-family: $font-family_5;
					font-size: 15px;
					font-weight: 500;
				}

				a {
					font-family: $font-family_2;
					color: $color_7;
					font-size: 13px;
					display: flex;
					margin-top: 13px;
					margin-left: -110px;
					transition: .5s ease;
					transition-delay: .2s;
					opacity: 0;

					&:hover {
						text-decoration: underline;
					}
				}
			}

			&:hover {
				.info {
					top: 160px;

					a {
						margin-left: 0;
						opacity: 1;
					}
				}
			}
		}
	}
}

.news {
	.content {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		.card {
			width: 290px;
			box-shadow: 0px 0px 36px -6px rgb(1 1 1 / 18%);
			margin: 10px;

			.image {
				img {
					width: 100%;
					height: 200px;
					object-fit: cover;
				}
			}

			.info {
				padding: 15px;
				margin-bottom: 18px;

				span {
					font-size: 15px;
					display: block;
					font-family: $font-family_2;
					text-align: center;
					margin-top: -9px;
					font-weight: 400;
				}

				h3 {
					font-size: 24px;
					color: $color_9;
					margin-top: 9px;
				}

				p {
					font-size: 12px;
					font-family: $font-family_2;
					line-height: 16px;
					margin-top: 5px;
				}

				a {
					font-size: 14px;
					float: left;
					color: $color_1;
					font-family: $font-family_2;
					font-weight: 500;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

footer {
	padding: 0px;
	padding-top: 50px;
	background: #333;
	display: flex;
	flex-direction: column;

	.content {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		.about-us {
			width: 25%;
			padding: 20px;
			margin: 10px;

			p {
				color: $color_4;
				font-size: .8em;
				line-height: 18px;
				margin-bottom: 23px;
			}

			.icon {
				a {
					display: flex;
					float: left;
					width: 20px;
					height: 20px;
					border-radius: 100%;
					color: $color_4;
					background: #221a1a;
					padding: 4px;
					justify-content: center;
					align-items: center;
					margin-right: 6px;
					font-size: 13px;

					&:hover {
						color: $color_11;
						background: #fff;
					}
				}
			}
		}

		.contact-us {
			width: 25%;
			padding: 20px;
			margin: 10px;

			.contacts {
				margin-bottom: 10px;
				width: 250px;
				display: flex;
				align-items: center;

				i {
					font-size: 26px;
					color: $color_1;
					float: left;
				}
			}

			span {
				font-family: $font-family_2;
				font-size: 13px;
				display: flex;
				color: $color_4;
				line-height: 20px;
				margin-left: 10px;
				letter-spacing: 1px;
			}
		}

		.useful-links {
			width: 40%;
			padding: 20px;
			margin: 10px;

			ul {
				list-style: none;
				padding: 0;
			}

			li {
				display: flex;
				color: $color_4;
				padding: 6px 0;
				border-bottom: 1px solid #dddddd70;
				align-items: center;
				font-family: $font-family_2;
				font-size: 15px;
				cursor: pointer;

				&:first-child {
					padding-top: 0;
				}

				&:last-child {
					border: none;
				}
			}

			i {
				font-size: 14px;
				margin-right: 8px;
			}
		}

		.recent-post {
			width: 450px;
			padding: 20px;
			margin: 10px;

			.info {
				h3 {
					color: $color_4;
					float: left;
					font-size: 17px;
					text-align: center;
					padding: 5px;
					background: #dddddd47;
					border-radius: 3px;
					margin-right: 6px;
				}

				margin-top: 13px;

				h2 {
					color: $color_12;
					font-size: 19px;
					width: 223px;
				}

				span {
					font-family: $font-family_2;
					color: $color_4;
					font-size: 11px;
					margin-right: 10px;
				}

				i {
					margin-right: 5px;
					color: $color_12;
				}
			}
		}
	}

	.copyright {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 20px 200px;
		border-top: 1px solid #ffffff52;

		.col-left {
			h3 {
				font-family: $font-family_2;
				font-size: 15px;
				font-weight: 500;
				color: $color_4;
			}
		}

		.col-right {
			a {
				font-size: 15px;
				font-family: $font-family_2;
				font-weight: 500;
				color: $color_4;
				margin-left: 20px;
			}
		}
	}
}

.footer-h3 {
	font-size: 28px;
	color: $color_4;
	margin-bottom: 24px;
}

.reveal {
	position: relative;
	// transform: translateY(150px);
	// opacity: 0;
	transition-timing-function: ease;
	transition: all 1s;
}

.reveal.active {
	transform: translateY(0px);
	opacity: 1;
}

.revealLeft {
	position: relative;
	// transform: translateX(-150px);
	// opacity: 0;
	transition-timing-function: ease;
	transition: all 1s;
}

.revealLeft.active {
	transform: translateX(0);
	opacity: 1;
}

.revealRight {
	position: relative;
	// transform: translateX(150px);
	// opacity: 0;
	transition-timing-function: ease;
	transition: all 1s;
}

.revealRight.active {
	transform: translateX(0);
	opacity: 1;
}

// .content {
// 	overflow: hidden;
// }

.to-top {
	background: #2b2b2b;
	position: fixed;
	bottom: 16px;
	right: 32px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	// display: flex;
	align-items: center;
	justify-content: center;
	font-size: 21px;
	color: $color_4;
	text-decoration: none;
	transition: all .5s;
	box-shadow: 0 0 4px -1px;
	padding: 17px;
	z-index: 10000;

}




.contact-us {
	width: 300px;
	padding: 20px;
	margin: 10px;
	font-size: large;

	.contacts {
		margin-bottom: 10px;
		width: 250px;
		display: flex;
		align-items: center;

		i {
			font-size: 26px;
			color: $color_1;
			float: left;
		}
	}

	span {
		font-family: $font-family_2;
		font-size: 13px;
		display: flex;
		color: $color_4;
		line-height: 20px;
		margin-left: 10px;
		letter-spacing: 1px;
	}
}

.text-justify {
	text-align: justify;
}

.text-blue {
	color: blue;
}

.text-md {
	font-size: 16px !important;
}

.all-service-button {
	padding: 2px 10px;
	margin: auto;
	background-color: #0072b1;
	color: white;
}
.partner {
	padding: 100PX 10px;
	background-color: #f7f7f7;

	.content {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		.card {
			max-width: 280px;
			padding: 3px;
			background: rgb(235, 235, 235);
			border-radius: 3px;
			margin: 10px;
			transition: .4s ease;
			text-align: center;
			border: 1px solid rgb(201, 201, 201);

			.partner-image {
				img {
					width: 100%;
				}
			}

			.partner-info {
				h3 {
					font-size: 20px;
					color: rgb(19, 19, 19);
					text-align: center;
					margin: 12px 0 6px 0;
				}

				p {
					font-size: 16px;
					color: black;
					text-align: center;
					line-height: 19px;
				}
			}
		}
	}
}