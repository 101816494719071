$color_1: skyblue;
$color_2: black;
$color_3: #a6d6e4;
$color_4: #fff;
$color_5: #ffff;
$color_6: #FFf;
$color_7: white;
$color_8: #826767;
$color_9: #333;
$color_10: #FFF;
$color_11: #000;
$color_12: #ddd;
$font-family_1: bebas, sans-serif;
$font-family_2: montserrat, sans-serif;
$font-family_3: montserrat, sans-serif;
$font-family_4: "candara", sans-serif;
$font-family_5: momntserrat, sans-serif;